

export default {
  computed: {
    structuredData () {
      return JSON.stringify(this.jsonld)
    }
  },
  data () {
    return {
      jsonld: {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: 'infoDiagram',
        url: this.$config.baseURL,
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${this.$config.baseURL}/search?query={search_term_string}`
          },
          'query-input': 'required name=search_term_string'
        }
      }
    }
  }
}
